var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"p-3 mt-5"},[_c('loading-flux',{attrs:{"value":_vm.loading}}),_c('section',{staticClass:"d-flex flex-column flex-sm-row justify-content-between"},[_c('h2',{staticClass:"ml-1 my-2"},[_vm._v(" Solicitudes de compra ")]),_c('v-breadcrumbs',{attrs:{"items":_vm.breadcrumb}})],1),_c('v-row',{staticClass:"mt-1 mb-5",attrs:{"align-content":"start","align-md":"center"}},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","md":"4","sm":"12"}},[_c('filter-project',{attrs:{"label":"Filtrar por proyecto","projects":_vm.projects.items
            ? _vm.projects.items.filter(function (item) { return item.warehouse; })
            : [],"getProjectsFunction":_vm.getProjects},on:{"cleanProject":_vm.cleanCurrentProject,"inputProject":function($event){_vm.updateCurrentProject($event);
          _vm.getPurchaseRequestsData(_vm.filters.page);}}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","md":"4","sm":"12"}},[_c('v-select',{attrs:{"items":Object.entries(_vm.MAP_STATUS_PURCHASE_REQUESTS).map(
            function (ref) {
                      var value = ref[0];
                      var name = ref[1].name;

                      return ({
              value: value,
              name: name,
            });
}
          ),"item-text":"name","item-value":"value","label":"Filtrar por estatus","clearable":""},on:{"input":function($event){return _vm.getPurchaseRequestsData(_vm.filters.page)}},model:{value:(_vm.filters.status),callback:function ($$v) {_vm.$set(_vm.filters, "status", $$v)},expression:"filters.status"}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","md":"4","sm":"12"}},[_c('v-text-field',{attrs:{"clearable":"","placeholder":"Buscar:"},on:{"input":function($event){return _vm.getPurchaseRequestsData(1)}},model:{value:(_vm.filters.filter),callback:function ($$v) {_vm.$set(_vm.filters, "filter", $$v)},expression:"filters.filter"}})],1)],1),_c('div',{staticClass:"d-flex align-items-center justify-content-end mb-2 flex-column flex-sm-row mb-1"},[_c('h5',{staticClass:"my-0"},[_vm._v(" Total: "+_vm._s(_vm.purchaseRequests.total)+" ")])]),_c('v-data-table',{staticClass:"px-1 elevation-3",attrs:{"headers":_vm.tableHeaders,"items":_vm.wrappedPurchaseRequests,"items-per-page":10,"page":_vm.filters.page,"hide-default-footer":"","loading":_vm.loadingPurchaseRequest,"loading-text":"Cargando solicitudes de compra","no-data-text":"Sin solicitudes de compra"},on:{"update:page":function($event){return _vm.$set(_vm.filters, "page", $event)}},scopedSlots:_vm._u([{key:"item.project",fn:function(ref){
          var item = ref.item;
return [_c('p',{staticClass:"text-left my-0"},[_vm._v(" "+_vm._s(item.project ? "Proyecto" : "CECO")+" ")])]}},{key:"item.code",fn:function(ref){
          var item = ref.item;
return [_c('p',{staticClass:"text-left my-0"},[_vm._v(" "+_vm._s(item.project ? item.project.code : item.cost_center.code)+" ")])]}},{key:"item.sap_code",fn:function(ref){
          var item = ref.item;
return [_c('p',{staticClass:"text-left my-0"},[_vm._v(" "+_vm._s(item.sap_code)+" ")])]}},{key:"item.stage_code",fn:function(ref){
          var item = ref.item;
return [_c('p',{staticClass:"my-0"},[_vm._v(" "+_vm._s(item.stage_code ? item.stage_code : "-")+" ")])]}},{key:"item.status",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"d-flex align-items-center justify-content-start"},[_c('v-chip',{staticClass:"text-white",attrs:{"label":"","small":"","color":_vm.getMappedStatusPurchaseRequest(
              item.status_manual ? item.status_manual : item.status
            ).color}},[_vm._v(" "+_vm._s(_vm.getMappedStatusPurchaseRequest( item.status_manual ? item.status_manual : item.status ).name)+" ")])],1)]}},{key:"item.created_at",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"mt-2",staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(new Date(item.created_at).toLocaleDateString())+" ")])]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary","x-small":""},on:{"click":function($event){return _vm.openModalMaterialsPurchaseRequest(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-view-list-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver materiales de la solicitud")])])],1)]}}])}),_c('div',{staticClass:"my-3 float-right"},[_c('v-pagination',{attrs:{"total-visible":"8","length":_vm.purchaseRequests.number_of_pages
          ? _vm.purchaseRequests.number_of_pages
          : 1},model:{value:(_vm.filters.page),callback:function ($$v) {_vm.$set(_vm.filters, "page", $$v)},expression:"filters.page"}})],1),_c('modal-materials-purchase-request',{attrs:{"modalAction":_vm.dialogMaterialsPurchaseRequest,"closeModalMaterialsPurchaseRequest":_vm.closeModalMaterialsPurchaseRequest,"currentPurchaseRequest":_vm.currentPurchaseRequest}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }