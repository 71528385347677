var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-dialog',{attrs:{"max-width":"1400px","scrollable":""},on:{"keydown":_vm.executeCloseModalPurchaseOrders,"click:outside":_vm.executeCloseModalPurchaseOrders},model:{value:(_vm.modalAction),callback:function ($$v) {_vm.modalAction=$$v},expression:"modalAction"}},[_c('v-card',[_c('div',{staticClass:"p-4 pb-0"},[_c('h4',{staticClass:"mb-5"},[_vm._v(" Ordenes de Compra ")]),(_vm.currentMaterial)?_c('h5',[_vm._v(" COD Material: "),_c('b',[_vm._v(" "+_vm._s(_vm.currentMaterial.material.code)+" ")]),_vm._v(" | Descripción: "),_c('b',[_vm._v(" "+_vm._s(_vm.currentMaterial.material.name)+" ")])]):_vm._e()]),_c('hr'),_c('v-card-text',[_c('v-container',{staticClass:"pt-0 px-0"},[(_vm.currentMaterial)?_c('v-data-table',{staticClass:"px-2 elevation-4 pt-2 ",attrs:{"headers":_vm.tableHeaders,"items":_vm.currentMaterial.purchase_order_items,"items-per-page":200,"hide-default-footer":"","loading-text":"Cargando ordenes","item-key":"id","no-data-text":"Sin ordenes de compra"},on:{"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.code",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-content-center"},[_c('v-chip',{staticClass:"mt-0 text-white",attrs:{"label":"","small":"","color":"primary"}},[_vm._v(" "+_vm._s(item.purchase_order.sap_code)+" ")])],1)]}},{key:"item.user",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-content-center"},[_vm._v(" "+_vm._s(item.purchase_order.document_owner.email)+" ")])]}},{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"mt-4",staticStyle:{"font-size":"11px"}},[_vm._v(" "+_vm._s(item.quantity)+" ")])]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"ml-5 mt-4",staticStyle:{"font-size":"11px"}},[_vm._v(" "+_vm._s(item.price.toLocaleString("es-CL", { style: "currency", currency: "CLP", }))+" ")])]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"ml-5 mt-4",staticStyle:{"font-size":"11px"}},[_vm._v(" "+_vm._s(item.purchase_order.total.toLocaleString("es-CL", { style: "currency", currency: "CLP", }))+" ")])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"mt-2",staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(item.purchase_order.created_at ? _vm.moment(item.purchase_order.created_at).format( "DD/MM/YYYY HH:mm [hrs]" ) : "Sin definir")+" ")])]}},{key:"item.delivery_date",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"mt-2",staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(item.delivery_date ? _vm.moment(item.delivery_date).format( "DD/MM/YYYY HH:mm [hrs]" ) : "Sin definir")+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"disabled":!item.inventory_entries.length,"icon":"","color":"success","x-small":""},on:{"click":function($event){return _vm.openModalInventoryEntries(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-clipboard-check ")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver Entradas de Mercancia asociadas")])])],1)]}}],null,false,1959939389)}):_vm._e()],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","outlined":""},on:{"click":_vm.executeCloseModalPurchaseOrders}},[_c('small',[_vm._v(" Cerrar ")])])],1)],1)],1),_c('modal-inventory-entries',{attrs:{"modalAction":_vm.dialogInventoryEntries,"closeModalInventoryEntries":_vm.closeModalInventoryEntries,"currentPurchaseOrder":_vm.currentPurchaseOrder}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }