<template>
  <section>
    <loading-flux :value="loading"></loading-flux>
    <v-dialog
      v-model="modalAction"
      max-width="800px"
      scrollable
      @keydown="executeCloseModalEtaMaterials"
      @click:outside="executeCloseModalEtaMaterials"
    >
      <v-card>
        <div class="p-4 pb-0">
          <h5>
            Asignar ETA a
            {{
              materialsToDefineEta.length > 1
                ? "Materiales seleccionados"
                : "Material seleccionado"
            }}
          </h5>
        </div>
        <hr />
        <v-card-text>
          <v-container class="pt-0">
            <v-form @submit.prevent="sendUpdateEtaMaterials">
              <v-row align-content="center">
                <v-col cols="12" md="6" sm="12" class="pt-0">
                  <label for="comments" class="mt-2 mb-0">
                    Fecha ETA*
                  </label>
                  <v-menu
                    ref="menu"
                    v-model="datepicker_menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="delivery_date_formatted"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :error="$v.form.delivery_date.$invalid && submitUpload"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="$v.form.delivery_date.$model"
                      scrollable
                      locale="es"
                      :min="minDateDelivery"
                      @input="datepicker_menu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="6" sm="12" class="pt-0">
                  <label for="comments" class="mt-2 mb-0">
                    Hora ETA*
                  </label>
                  <v-menu
                    ref="timepicker_menu"
                    v-model="timepicker_menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="form.delivery_time"
                        prepend-icon="mdi-clock"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :error="$v.form.delivery_time.$invalid && submitUpload"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-model="$v.form.delivery_time.$model"
                      locale="es"
                      full-width
                      @click:minute="
                        $refs.timepicker_menu.save(form.delivery_time)
                      "
                    ></v-time-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            outlined
            @click="executeCloseModalEtaMaterials"
          >
            <small>
              Cancelar
            </small>
          </v-btn>
          <v-btn
            @click="sendUpdateEtaMaterials"
            color="teal accent-4 text-white"
            small
            class="float-right"
          >
            Asignar ETA
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { mapActions } from "vuex";
import { authUsuarioComputed } from "@/state/helpers";
import { preventCloseIfNotKeyEventEsc } from "@/helpers/common";
import moment from "moment";
import { required } from "vuelidate/lib/validators";
import swal2 from "sweetalert2";
export default {
  props: {
    modalAction: {
      type: Boolean,
      default: false,
    },
    closeModalEtaMaterials: {
      type: Function,
      default: () => {},
    },
    materialsToDefineEta: {
      type: Array,
      default: () => [],
    },
  },
  validations: {
    form: {
      delivery_date: { required },
      delivery_time: { required },
    },
  },
  data() {
    return {
      datepicker_menu: false,
      timepicker_menu: false,
      loading: false,
      form: {
        delivery_date: moment().format("YYYY-MM-DD"),
        delivery_time: moment()
          .set({ minute: 0 })
          .format("HH:mm"),
      },
      submitUpload: false,
    };
  },
  methods: {
    ...mapActions({
      updateEtaMaterialsRequest: "supplying/updateEtaMaterialsRequest",
    }),
    async sendUpdateEtaMaterials() {
      let self = this;
      self.submitUpload = true;
      if (!self.$v.form.$invalid) {
        self.loading = true;
        let delivery_date = `${self.form.delivery_date}T${self.form.delivery_time}:00`;
        let payload = {
          items: self.materialsToDefineEta.map((item) => ({
            id: item.id,
            delivery_date,
          })),
          user: self.user.email,
        };
        const resp = await self.updateEtaMaterialsRequest(payload);
        if (!resp.status || resp.status === 400) {
          self.loading = false;
          return swal2.fire({
            icon: "error",
            title: "Error",
            text: resp.data.detail,
            position: "top-end",
            showConfirmButton: false,
            toast: true,
            timer: 10000,
            timerProgressBar: true,
          });
        }
        if (resp.status == 200) {
          swal2.fire({
            icon: "success",
            title: "Excelente",
            text: "ETA de materiales actualizado correctamente",
            position: "top-end",
            showConfirmButton: false,
            toast: true,
            timer: 5000,
            timerProgressBar: true,
          });
          this.$emit("etaMaterialsUpdated", {
            materials: self.materialsToDefineEta,
            delivery_date,
            user_delivery_date: self.user.email,
          });
          this.closeModalEtaMaterials(true);
          this.resetForm();
        }
        this.loading = false;
      }
    },
    executeCloseModalEtaMaterials($event) {
      let validationEvent = preventCloseIfNotKeyEventEsc($event);
      if (validationEvent) {
        this.resetForm();
        this.closeModalEtaMaterials($event);
      }
    },
    resetForm() {
      this.form = {
        delivery_date: moment().format("YYYY-MM-DD"),
        delivery_time: moment()
          .set({ minute: 0 })
          .format("HH:mm"),
      };
      this.submitUpload = false;
    },
  },
  computed: {
    ...authUsuarioComputed,
    delivery_date_formatted() {
      if (this.form.delivery_date) {
        return moment(this.form.delivery_date).format("DD/MM/YYYY");
      }
      return null;
    },
    minDateDelivery() {
      return moment().format("YYYY-MM-DD");
    },
  },
};
</script>

<style></style>
