<template>
  <section>
    <v-dialog
      v-model="modalAction"
      max-width="1400px"
      scrollable
      @keydown="executeCloseModalMaterialsPurchaseRequest"
      @click:outside="executeCloseModalMaterialsPurchaseRequest"
    >
      <v-card v-if="currentPurchaseRequest">
        <div class="p-4 pb-0">
          <h5>
            COD Solicitud:
            <b>
              {{ currentPurchaseRequest.sap_code }}
            </b>
            | Tipo de solicitud:
            <b>
              {{ currentPurchaseRequest.request_type.name }}
            </b>
          </h5>
        </div>
        <hr />
        <v-card-text>
          <v-container class="pt-0 px-0">
            <div class="d-flex justify-content-between align-items-end mb-5">
              <section class="w-50">
                <v-text-field
                  v-model="filters.filter"
                  class="px-2"
                  clearable
                  placeholder="Buscar:"
                  hide-details
                ></v-text-field>
              </section>
              <!-- <v-btn
                :disabled="!materialsSelected.length"
                @click="openModalEtaMaterials"
                color="teal accent-4 text-white"
                small
                class="ml-3"
              >
                <v-icon small class="mr-1">
                  mdi-calendar-outline
                </v-icon>
                <span>
                  Asignar ETA
                </span>
              </v-btn> -->
            </div>
            <!-- <div class="text-right my-2">
              <small class="text-info">
                <v-icon color="blue">
                  mdi-information-outline
                </v-icon>
                Selecciona las materiales para asignar ETA
              </small>
            </div> -->
            <v-data-table
              class="px-2 elevation-4 pt-2 "
              :headers="tableHeaders"
              :items="filteredMaterials"
              :items-per-page="200"
              hide-default-footer
              loading-text="Cargando materiales"
              item-key="id"
              no-data-text="Sin materiales"
              @page-count="pageCount = $event"
            >
              <template v-slot:item.code="{ item }">
                <div class="d-flex justify-content-center">
                  <v-chip label small color="primary" class="mt-0 text-white">
                    {{ item.material.code }}
                  </v-chip>
                </div>
              </template>
              <template v-slot:item.name="{ item }">
                <p class="text-left my-0" style="font-size: 11px">
                  {{ item.material.name }}
                </p>
              </template>
              <template v-slot:item.unit="{ item }">
                <p class="text-center my-0" style="font-size: 11px">
                  {{ item.material.measurement_unit }}
                </p>
              </template>
              <template v-slot:item.quantity="{ item }">
                <p class="text-right mt-4" style="font-size: 11px">
                  {{ item.quantity }}
                </p>
              </template>
              <template v-slot:item.purchased_quantity="{ item }">
                <p class="text-right mt-4" style="font-size: 11px">
                  {{ item.purchased_quantity }}
                </p>
              </template>
              <template v-slot:item.status="{ item }">
                <v-edit-dialog :ref="`edit-dialog-${item.material.code}`">
                  <div class="d-flex align-items-center">
                    <v-chip
                      label
                      small
                      :color="
                        getMappedStatusPurchaseRequest(
                          item.status_manual ? item.status_manual : item.status
                        ).color
                      "
                      class="text-white"
                    >
                      {{
                        getMappedStatusPurchaseRequest(
                          item.status_manual ? item.status_manual : item.status
                        ).name
                      }}
                    </v-chip>
                  </div>
                  <!-- <template v-slot:input>
                    <v-select
                      :value="
                        item.status_manual ? item.status_manual : item.status
                      "
                      label="Modificar estatus"
                      single-line
                      :items="
                        Object.entries(MAP_STATUS_PURCHASE_REQUESTS).map(
                          ([value, { name }]) => ({
                            value,
                            name,
                          })
                        )
                      "
                      item-text="name"
                      item-value="value"
                      @change="
                        updateStatusMaterial($event, item);
                        closeCurrentEditDialog(item);
                      "
                    ></v-select>
                  </template> -->
                </v-edit-dialog>
              </template>

              <template v-slot:item.actions="{ item }">
                <div class="d-flex align-items-center justify-content-center">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :disabled="!item.purchase_order_items.length"
                        @click="openModalPurchaseOrders(item)"
                        icon
                        class="mx-2"
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        x-small
                      >
                        <v-icon small>
                          mdi-clipboard-text
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Ver Ordenes de Compra asociadas</span>
                  </v-tooltip>
                </div>
              </template>
              -->
            </v-data-table>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            outlined
            @click="executeCloseModalMaterialsPurchaseRequest"
          >
            <small>
              Cerrar
            </small>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <modal-eta-materials
      :modalAction="dialogEtaMaterials"
      :closeModalEtaMaterials="closeModalEtaMaterials"
      :materialsToDefineEta="materialsSelected"
      @etaMaterialsUpdated="updateEtaMaterials"
    >
    </modal-eta-materials>
    <modal-purchase-orders
      :modalAction="dialogPurchaseOrders"
      :closeModalPurchaseOrders="closeModalPurchaseOrders"
      :currentMaterial="currentMaterial"
    ></modal-purchase-orders>
  </section>
</template>

<script>
import { mapActions } from "vuex";
import { authUsuarioComputed } from "@/state/helpers";
import { preventCloseIfNotKeyEventEsc } from "@/helpers/common";
import moment from "moment";
import { MAP_STATUS_PURCHASE_REQUESTS } from "@/constants/supplying/purchaseRequests";
import ModalEtaMaterials from "./ModalEtaMaterials.vue";
import ModalPurchaseOrders from "./ModalPurchaseOrders.vue";
import swal2 from "sweetalert2";

export default {
  components: {
    ModalEtaMaterials,
    ModalPurchaseOrders,
  },
  props: {
    modalAction: {
      type: Boolean,
      default: false,
    },
    closeModalMaterialsPurchaseRequest: {
      type: Function,
      default: () => {},
    },
    currentPurchaseRequest: {
      default: null,
    },
  },
  data() {
    return {
      moment,
      MAP_STATUS_PURCHASE_REQUESTS,
      loading: false,
      currentMaterials: [],
      currentMaterial: null,
      materialsSelected: [],
      dialogEtaMaterials: false,
      dialogPurchaseOrders: false,
      filters: {
        filter: "",
      },
      tableHeaders: [
        {
          text: "Acción",
          align: "center",
          value: "actions",
          className: "px-0",
          width: "3%",
          sortable: false,
        },
        {
          text: "Código Material",
          align: "start",
          sortable: false,
          value: "code",
          width: "5%",
        },
        {
          text: "Estatus",
          align: "center",
          sortable: false,
          value: "status",
        },
        {
          text: "Descripción",
          class: "text-left",
          sortable: false,
          value: "name",
        },
        {
          text: "Unidad",
          align: "center",
          sortable: false,
          value: "unit",
        },
        {
          text: "Cantidad solicitada",
          align: "center",
          sortable: false,
          width: "10%",
          value: "quantity",
        },
        {
          text: "Cantidad comprada",
          align: "center",
          sortable: false,
          width: "10%",
          value: "purchased_quantity",
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      updatEtaCurrentPurchaseRequest:
        "supplying/updatEtaCurrentPurchaseRequest",
      updateStatusMaterialPurchaseRequest:
        "supplying/updateStatusMaterialPurchaseRequest",
    }),
    updateEtaMaterials({ materials, delivery_date, user_delivery_date }) {
      this.updatEtaCurrentPurchaseRequest({
        id: this.currentPurchaseRequest.id,
        materials,
        delivery_date,
        user_delivery_date,
      });
    },
    executeCloseModalMaterialsPurchaseRequest($event) {
      let validationEvent = preventCloseIfNotKeyEventEsc($event);
      if (validationEvent) {
        this.currentMaterials = [];
        this.materialsSelected = [];
        this.closeModalMaterialsPurchaseRequest($event);
      }
    },
    setMaterialsCurrentRequest(materials) {
      this.currentMaterials = materials;
    },
    getMappedStatusPurchaseRequest(status) {
      return MAP_STATUS_PURCHASE_REQUESTS[status]
        ? MAP_STATUS_PURCHASE_REQUESTS[status]
        : { name: status, color: "grey darken-3" };
    },
    openModalPurchaseOrders(item) {
      this.currentMaterial = item;
      this.dialogPurchaseOrders = true;
    },
    closeModalPurchaseOrders() {
      this.currentMaterial = null;
      this.dialogPurchaseOrders = false;
    },
    openModalEtaMaterials() {
      this.dialogEtaMaterials = true;
    },
    closeModalEtaMaterials() {
      this.materialsSelected = [];
      this.dialogEtaMaterials = false;
    },
    async updateStatusMaterial(newStatus, material) {
      let self = this;
      self.loading = true;
      let payload = {
        request_id: self.currentPurchaseRequest.id,
        status_text: newStatus,
        items: [
          {
            status: MAP_STATUS_PURCHASE_REQUESTS[newStatus].value,
            id: material.id,
            user: self.user.email,
          },
        ],
      };
      const resp = await self.updateStatusMaterialPurchaseRequest(payload);
      if (!resp.status || resp.status === 400) {
        self.loading = false;
        return swal2.fire({
          icon: "error",
          title: "Error",
          text: resp.data.detail,
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 10000,
          timerProgressBar: true,
        });
      }
      if (resp.status == 200) {
        swal2.fire({
          icon: "success",
          title: "Excelente",
          text: "Estatus de material actualizado correctamente",
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 5000,
          timerProgressBar: true,
        });
      }
      this.loading = false;
    },
    openCurrentEditDialog(item) {
      this.$refs[`edit-dialog-${item.material.code}`].isActive = true;
    },
    closeCurrentEditDialog(item) {
      this.$refs[`edit-dialog-${item.material.code}`].isActive = false;
    },
  },
  computed: {
    ...authUsuarioComputed,
    filteredMaterials() {
      if (this.filters.filter) {
        let filter = this.filters.filter.trim().toLowerCase();
        return this.currentMaterials.filter(({ material }) => {
          return (
            material.code.toLowerCase().includes(filter) ||
            material.name.toLowerCase().includes(filter) ||
            material.measurement_unit.toLowerCase().includes(filter)
          );
        });
      }
      return this.currentMaterials;
    },
  },
  watch: {
    currentPurchaseRequest(newRequest) {
      if (newRequest) {
        this.setMaterialsCurrentRequest(newRequest.items);
      }
    },
  },
};
</script>

<style></style>
